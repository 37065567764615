import React, { useEffect, useState } from "react";
import { JournalistHeader } from "./JournalistHeader";
import { useParams } from "react-router-dom";
import axios from 'axios'
import styles from './Style.module.css'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Grommet,
  Heading,
  Image,
  Stack,
  Text,
  Tip,
} from 'grommet'

import { Link } from "react-router-dom";
import logo from '../images/logo.png'
import { Article, Info } from "grommet-icons";

const ArticleList = () => {
  const {id} = useParams();
  const [articles, SetArticles] = useState([])
  const [pendingArticles, setPendingArticles] = useState([])
  const token = localStorage.getItem('access_token');

  useEffect(() => {
      if(!token){                   
          window.location.href = '/login'
      }
      const fetch = async () => {
        try {
          const { articleData } = axios.get(`https://leo-api.xyz/api/articles/`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              }
            }).then(response => {
              console.log(`response: ${response.data}`)

              SetArticles(response.data)

              setPendingArticles(response.data.filter(article => article.published === false 
                                                                    && article.archivedPublished === false
                                                                    && article.archivedNonPublished === false)
                                                .sort((a, b) => b.likes.length - a.likes.length  ));
            });

        } catch (err) {
          console.error(err);
          }
      };

      fetch();


    }, []);

  return (
      <div>
          <JournalistHeader />
          <Grommet>

            <Box width={'xlarge'} pad={{ horizontal: 'large', vertical: 'none' }} direction="row" gap={"small"}>
              <Heading weight='bold'  level={3}>
                  Top Pending Articles
              </Heading>
              <Tip
                  content={
                    <Box pad="small" background="light-2" round="small">
                      <Text> All written articles will appear here to be peer reviewed. The top reviewed articles are published onto the main site</Text>
                    </Box>
                  }
                >
                  <Button icon={<Info />} plain />
            </Tip>
            
            </Box>
              
              <Grid
              pad={{ horizontal: 'large', vertical: 'none' }}
              columns={['1/3','1/3', '1/3']}
              fill
              >
                {pendingArticles.map((article) => (
                  
                    <Box key={article.id} elevation="small" height='auto' border={{side: 'all', color: "white"}}>
                        <Link to={`/article/${article.id}`} style={{ textDecoration: 'none' }}>
                            <Box>
                                <Stack anchor="bottom-left">
                                    <Image src={article.image_url != null ? article.image_url : "http://leo-api.xyz/media/images/logo_yJELn9V.png"}
                                     style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
                                    <Card background={{opacity:'strong', color: 'black'}}>    
                                        <Box pad="small">
                                            <CardHeader>
                                                <Text size='xlarge' weight='bold' color="#47B0EC">
                                                    {article.headline}
                                                </Text>
                                            </CardHeader>
                                        </Box>
                                    </Card>
                                </Stack>
                                <Box pad={'medium'}>
                                    <Text size='large' weight={'lighter'} color="black">
                                        {article.subtitle}
                                    </Text>
                                </Box>
                            </Box>
                            <Box pad={'medium'}>
                                <Text size='large' weight={'lighter'} color="black">
                                    {article.likes_count} {article.likes_count === 1 ? 
                                                                      <Text size='large' weight={'lighter'} color="black"> endorsement  </Text>
                                                                    : <Text size='large' weight={'lighter'} color="black"> endorsements </Text>}
                                </Text>
                            </Box>

                        </Link>
                    </Box>
                ))}
                
            </Grid>

          </Grommet>
          
      </div>
  )
};

export default ArticleList;

